
export enum ETripEventType {
  'Trip-Start' = 'Trip Start',
  'Trip-Finish' = 'Trip Finish'
}

export enum EIncidentType {
  'All' = 'violations.all',
  'Cellphone-Distracted-Driving' = 'violations.CellphoneDistractedDriving',
  'Distracted-Driving' = 'violations.DistractedDriving',
  'Drowsy-Driving-Detected' = 'violations.DrowsyDrivingDetected',
  //'Forward-Collision-Warning' = 'violations.ForwardCollisionWarning',
  'Harsh-Acceleration' = 'violations.HarshAcceleration',
  'acceleration' = 'violations.HarshAcceleration',
  'Harsh-Braking' = 'violations.HarshBraking',
  'Cornering' = 'violations.HarshCornering',
  'Max-Speed-Exceeded' = 'violations.MaxSpeedExceeded',
  'Speed-Limit-Violation' = 'violations.SpeedLimitViolation',
  'Stop-Sign-Violation' = 'violations.StopSignViolation',
  'Tail-Gating-Detected' = 'violations.Tailgating',
  'Traffic-Speed-Violated' = 'violations.TrafficSpeedViolated',
  'Smoking-Distracted-Driving' = 'violations.SmokingDistractedDriving',
  'Driver-SeatBelt-Non-Compliance' = 'violations.DriverSeatBeltViolation',
  'Passenger-SeatBelt-Non-Compliance' = 'violations.PassengerSeatBeltViolation',
  //'Lizard-Eye-Distracted-Driving' = 'violations.DistractedDriving',
  'Roll-Over-Detected' = 'violations.RollOverDetected',
  'cornering' = 'violations.HarshCornering',
  'braking' = 'violations.HarshBraking',
  
  'Camera-Lens-Covered' = 'violations.CameraLensCovered',
  'Uniform-Non-Compliance' = 'violations.UniformNonCompliance',
  //'Possible-Collision' = 'Possible-Collision',
  'TO_BE_DETERMINED' = 'violations.ToBeDetermined',
  'Lane-Drift-Found' = 'violations.LaneDriftFound',
    'max_speed' = 'violations.MaxSpeedExceeded',
}

export const remapTypeIn = (type: string) : string => {
  switch(type) {
    case "Cornering": return "Cornering"
    case "TailGating-Detected":
    case "Tailgating": return "Tailgating"
    case "MaxSpeedExceeded": return "Max-Speed-Exceeded"
    case "Cellphone-Distracted-Driving": return "Distracted-Driving"
    case "Drinking-Distracted-Driving": return "Distracted-Driving"
    default: return type
  }
}

export interface IOutgoingEventType {
  [key:string] : string
}

export const outgoingEventTypeMap: IOutgoingEventType = {
  'violations.all': null,
  'violations.Tailgating': 'Tail-Gating-Detected',
  'violations.TailGatingDetected': 'Tail-Gating-Detected',
  'violations.DistractedDriving': 'Distracted-Driving',
  'violations.ForwardCollisionWarning': 'Forward-Collision-Warning',
  'violations.HarshAcceleration': 'Harsh-Acceleration',
  'violations.HarshBraking': 'Harsh-Braking',
  'violations.HarshCornering': 'Cornering',
  'violations.Cornering': 'Cornering',
  'violations.SpeedLimitViolation': 'Speed-Limit-Violation',
  'violations.StopSignViolation': 'Stop-Sign-Violation',
  'violations.MaxSpeedExceeded': 'Max-Speed-Exceeded',
  'violations.DrowsyDrivingDetected': 'Drowsy-Driving-Detected',
  'violations.CellphoneDistractedDriving': 'Distracted-Driving',
  'violations.TrafficSpeedViolated': 'Traffic-Speed-Violated',
  'violations.SeatBeltViolation': 'SeatBelt-Non-Compliance',
  'violations.DriverSeatBeltViolation': 'Driver-SeatBelt-Non-Compliance',
  'violations.PassengerSeatBeltViolation': 'Passenger-SeatBelt-Non-Compliance',
  'violations.RollOverDetected': 'Roll-Over-Detected',
  'violations.CameraLensCovered': 'Camera-Lens-Covered',
  //'Possible-Collision' : 'Possible-Collision',

}

